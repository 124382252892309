import React from 'react';
import { Box, Heading, Text, Container, VStack, UnorderedList, ListItem, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const bgColor = 'black';
  const textColor = 'gray.100';

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Container maxW="container.xl" py={20}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="center" mb={6}>
            <RouterLink to="/">
              <Logo size="4xl" text="VoxAI" />
            </RouterLink>
          </Flex>
          <Heading as="h1" size="2xl" textAlign="center">
            {t("Privacy Policy")}
          </Heading>
          
          <Text fontSize="lg">
            {t("Last updated: 01-08-2024")}
          </Text>

          <Text fontSize="lg">
            {t("At VoxAI, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI-enabled call services ('Service'). By using our Service, you agree to the collection and use of information in accordance with this policy.")}
          </Text>

          <VStack spacing={6} align="stretch">
            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("1. Information We Collect")}
              </Heading>
              <Text>
                {t("We collect the following types of information:")}
              </Text>
              <UnorderedList mt={2} spacing={2}>
                <ListItem>{t("Personal information (e.g., name, email address, phone number)")}</ListItem>
                <ListItem>{t("Account information")}</ListItem>
                <ListItem>{t("Payment information")}</ListItem>
                <ListItem>{t("Call recordings and transcripts")}</ListItem>
                <ListItem>{t("Usage data and analytics")}</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("2. How We Use Your Information")}
              </Heading>
              <Text>
                {t("We use the collected information for various purposes, including:")}
              </Text>
              <UnorderedList mt={2} spacing={2}>
                <ListItem>{t("Providing and maintaining our Service")}</ListItem>
                <ListItem>{t("Improving and personalizing user experience")}</ListItem>
                <ListItem>{t("Processing transactions and sending related information")}</ListItem>
                <ListItem>{t("Sending technical notices, updates, and support messages")}</ListItem>
                <ListItem>{t("Responding to your comments and questions")}</ListItem>
                <ListItem>{t("Complying with legal obligations")}</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("3. Data Security")}
              </Heading>
              <Text>
                {t("We implement appropriate technical and organizational measures to protect the security of your personal information. This includes encryption of sensitive data, regular security audits, and strict access controls. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.")}
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("4. Compliance with Laws")}
              </Heading>
              <Text>
                {t("We comply with applicable data protection laws, including:")}
              </Text>
              <UnorderedList mt={2} spacing={2}>
                <ListItem>{t("General Data Protection Regulation (GDPR) for users in the European Economic Area (EEA)")}</ListItem>
                <ListItem>{t("California Consumer Privacy Act (CCPA) for California residents")}</ListItem>
                <ListItem>{t("Personal Information Protection and Electronic Documents Act (PIPEDA) for Canadian users")}</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("5. Your Rights")}
              </Heading>
              <Text>
                {t("Depending on your location, you may have certain rights regarding your personal data, including:")}
              </Text>
              <UnorderedList mt={2} spacing={2}>
                <ListItem>{t("The right to access your personal data")}</ListItem>
                <ListItem>{t("The right to rectify inaccurate personal data")}</ListItem>
                <ListItem>{t("The right to erasure ('right to be forgotten')")}</ListItem>
                <ListItem>{t("The right to restrict processing")}</ListItem>
                <ListItem>{t("The right to data portability")}</ListItem>
                <ListItem>{t("The right to object to processing")}</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("6. Changes to This Privacy Policy")}
              </Heading>
              <Text>
                {t("We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last updated' date. You are advised to review this Privacy Policy periodically for any changes.")}
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={3}>
                {t("7. Contact Us")}
              </Heading>
              <Text>
                {t("If you have any questions about this Privacy Policy or our data practices, please contact us at:")}
              </Text>
              <Text mt={2}>
                {t("Email: privacy@voxai.app")}
              </Text>
            </Box>
          </VStack>

          <Text fontStyle="italic" mt={6}>
            {t("By using our Service, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.")}
          </Text>
        </VStack>
      </Container>
      <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
        <Container maxW="container.xl">
          <Flex direction={["column", "row"]} justify="space-between" align="center">
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
              <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
              <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
              <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
            </VStack>
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
              <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
            </VStack>
            <VStack align="flex-start">
              <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
              <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
            </VStack>
          </Flex>
          <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {"voxai"}. {t("All rights reserved.")}
          </Text>
        </Container>
      </Box>

    </Box>
  );
};


export default PrivacyPolicy;
