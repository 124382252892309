import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box, Text, Button, VStack, SimpleGrid, Container, Flex, Icon, Table, Thead, Tbody, Tr, Th, Td, Heading, useBreakpointValue, useColorModeValue, Link, Menu, MenuButton, MenuList, MenuItem, IconButton, Input, FormControl, FormLabel, useToast, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Spinner, Checkbox, Collapse } from '@chakra-ui/react';
import Logo from './components/Logo';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { FaRobot, FaChartLine, FaPhoneAlt, FaTools, FaCalendarAlt, FaSearch, FaHeadset, FaWrench, FaInfoCircle, FaUtensils, FaTaxi, FaCalendarCheck, FaUser, FaLanguage, FaShieldAlt, FaClock } from 'react-icons/fa';
import { render } from '@react-email/render';
import { Email } from './emails/VerificationEmail';

const ENABLE_SIGNUP = false;

const animatedButtonStyles = {
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    background: 'linear-gradient(45deg, #00BFFF, #1E90FF, #4169E1, #0000FF, #8A2BE2, #9932CC, #8B008B, #00BFFF)',
    backgroundSize: '400%',
    zIndex: -1,
    filter: 'blur(5px)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out, background-position 20s linear',
    borderRadius: 'inherit',
  },
  '&:hover::before': {
    opacity: 1,
    backgroundPosition: '100% 50%',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
    borderRadius: 'inherit',
    zIndex: -1,
  },
};

const iconColors = ['purple.500', 'green.500', 'orange.500', 'pink.500', 'cyan.500', 'yellow.500'];

const FeatureCard = React.memo(({ icon, title, description, index }) => {
  const cardBg = 'gray.700';
  const borderColor = 'gray.700';
  const iconColor = iconColors[index % iconColors.length];
  return (
    <Box 
      bg={cardBg} 
      p={[4, 6]} 
      borderRadius="lg" 
      boxShadow="lg" 
      textAlign="center"
      transition="all 0.3s"
      _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
      border="1px"
      borderColor={borderColor}
    >
      <Icon 
        as={icon} 
        fontSize={["3xl", "4xl"]} 
        mb={4} 
        color={iconColor}
      />
      <Heading as="h3" size={["sm", "md"]} mb={2}>
        {title}
      </Heading>
      <Text fontSize={["sm", "md"]}>{description}</Text>
    </Box>
  );
});

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const bgColor = 'black';
  const textColor = 'gray.100';
  const topBarBg = 'black';
  const toast = useToast();

  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: ''
  });

  const handleLogin = () => navigate('/login');
  const handleSignUp = () => setIsSignupModalOpen(true);
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [showTos, setShowTos] = useState(false);

  const handleEarlyAccessSubmit = async (e) => {
    e.preventDefault();
    if (!tosAccepted) {
      toast({
        title: t("Terms of Service not accepted"),
        description: t("Please accept the Terms of Service to continue."),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const emailHtml = render(<Email firstName={formData.firstName} verificationUrl="PLACEHOLDER_URL" />);
      
      const response = await fetch('https://r.voxai.app/api/early-access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          country: formData.country,
          tosAccepted: tosAccepted,
          emailHtml: emailHtml
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.message && data.message.includes('already registered and verified')) {
          toast({
            title: "Already Registered",
            description: data.message,
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        } else {
          console.log('Early access request response:', data.message);
          toast({
            title: "Request Submitted",
            description: data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            country: ''
          });
          setIsSignupModalOpen(false);
        }
      } else {
        throw new Error(data.error || 'Network response was not ok');
      }
    } catch (error) {
      console.error('Error submitting early access request:', error);
      toast({
        title: "Submission Error",
        description: error.message || "There was an error submitting your request. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const countries = useMemo(() => [
    "Canada", "United States", "United Kingdom", "France", "Mexico"
  ], []);

  const buttonSize = useBreakpointValue({ base: "xs", md: "sm" });
  const headingSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const logoSize = useBreakpointValue({ base: "2xl", md: "3xl", lg: "4xl" });

  const sectionRefs = useRef([]);
  const lastScrollTop = useRef(0);
  const isScrolling = useRef(false);
  const rafId = useRef(null);

  useEffect(() => {
    // Enable smooth scrolling for anchor links
    const handleSmoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', handleSmoothScroll);
    });

    // Collect all section refs
    sectionRefs.current = ['hero', 'features', 'pricing'].map(id => document.getElementById(id));

    // Scroll to next/previous section based on scroll direction
    const handleScroll = () => {
      cancelAnimationFrame(rafId.current);
      rafId.current = requestAnimationFrame(() => {
        if (isScrolling.current) return;

        const st = window.pageYOffset || document.documentElement.scrollTop;
        const scrollingDown = st > lastScrollTop.current;
        lastScrollTop.current = st;

        const viewportHeight = window.innerHeight;
        const currentSection = sectionRefs.current.find(section => {
          const rect = section.getBoundingClientRect();
          return rect.top <= viewportHeight / 2 && rect.bottom >= viewportHeight / 2;
        });

        if (currentSection) {
          const currentIndex = sectionRefs.current.indexOf(currentSection);
          let targetSection;

          if (scrollingDown && currentIndex < sectionRefs.current.length - 1) {
            targetSection = sectionRefs.current[currentIndex + 1];
          } else if (!scrollingDown && currentIndex > 0) {
            targetSection = sectionRefs.current[currentIndex - 1];
          }

          if (targetSection) {
            isScrolling.current = true;
            targetSection.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
              isScrolling.current = false;
            }, 1000); // Adjust this timeout based on your scroll animation duration
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', handleSmoothScroll);
      });
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(rafId.current);
    };
  }, []);

  return (
    <Box bg={bgColor} minH="100vh" display="flex" flexDirection="column">
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MQRFQXKF"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
      <Box bg={topBarBg} py={2} px={4} borderBottom="" borderColor={useColorModeValue('gray.700', 'gray.800')} position="sticky" top="0" zIndex="sticky">
        <Container maxW="container.xl">
          <Flex alignItems="center" justifyContent="space-between">
            <RouterLink to="/">
              <Logo size={logoSize} text="VoxAI" />
            </RouterLink>
            <Flex alignItems="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label={t("Language")}
                  icon={<FaLanguage />}
                  variant="ghost"
                  color="gray.300"
                  _hover={{ color: "white" }}
                  mr={4}
                />
                <MenuList bg="black" borderColor="gray.700">
                  <MenuItem onClick={() => handleLanguageChange('en')} _hover={{ bg: 'gray.800' }}>English</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('fr')} _hover={{ bg: 'gray.800' }}>Français</MenuItem>
                </MenuList>
              </Menu>
              <Link href="#features" mr={4} color="gray.300" _hover={{ color: "white" }}>
                {t("Features")}
              </Link>
              <Link href="#pricing" mr={4} color="gray.300" _hover={{ color: "white" }}>
                {t("Pricing")}
              </Link>
              {ENABLE_SIGNUP && (
                <Button variant="ghost" colorScheme="gray" size={buttonSize} mr={2} onClick={handleSignUp}>
                  {t("Sign Up")}
                </Button>
              )}
              <Button 
                colorScheme="blue" 
                size={buttonSize} 
                variant="outline" 
                borderColor="blue.500" 
                color="white" 
                _hover={{ bg: "black" }}
                onClick={handleLogin}
              >
                <Text fontWeight="bold">{t("Login")}</Text>
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>

      <Box as="section" id="hero" minH="calc(100vh - 64px)" display="flex" alignItems="center" justifyContent="center" py={4} px={8} mb={20}>
        <Container maxW="container.xl">
          <Flex direction={["column", "column", "row"]} alignItems="center">
            <Box flex="1" mb={[8, 8, 0]} pr={[0, 0, 8]}>
              <Heading as="h1" size="2xl" fontWeight="bold" mb={4}>
                <Text as="span" color="purple.500">{t("call_to_action_title")}</Text>
              </Heading>
              <Text as="h2" fontSize="xl" mb={6} color="gray.300">
                {t("call_to_action_text")}
              </Text>
              <VStack spacing={4} align="stretch" width="100%">
                <Button 
                  leftIcon={<FaRobot />} 
                  variant="outline" 
                  colorScheme="blue" 
                  borderColor="blue.500" 
                  color="white" 
                  onClick={handleSignUp}
                  sx={animatedButtonStyles}
                  _hover={{ '&::before': { opacity: 1 } }}
                >
                  <Text fontWeight="bold">
                    {t("Request Beta Access")}
                  </Text>
                </Button>
                <SimpleGrid columns={[2, null, 3]} spacing={4}>
                  {[
                    { name: t("Receptionist"), icon: FaHeadset, enabled: true },
                    { name: t("Custom"), icon: FaUser, enabled: true },
                    { name: t("Technical Support"), icon: FaWrench, enabled: false },
                    { name: t("Information Line"), icon: FaInfoCircle, enabled: false },
                    { name: t("Restaurant Order"), icon: FaUtensils, enabled: false },
                    { name: t("Taxi dispatcher"), icon: FaTaxi, enabled: false },
                    { name: t("Appointment Confirmation"), icon: FaCalendarCheck, enabled: false },
                  ].map((subtype) => (
                    <Box key={subtype.name} position="relative">
                      <Button
                        leftIcon={<subtype.icon />}
                        variant="outline"
                        colorScheme={subtype.enabled ? "green" : "gray"}
                        borderRadius="full"
                        size="sm"
                        width="auto"
                        height="auto"
                        px={2}
                        py={1}
                        whiteSpace="normal"
                        textAlign="left"
                        opacity={subtype.enabled ? 1 : 0.5}
                        _hover={{
                          opacity: subtype.enabled ? 0.8 : 0.5,
                          cursor: subtype.enabled ? 'pointer' : 'not-allowed'
                        }}
                        position="relative"
                        borderColor={subtype.enabled ? "green.500" : "gray.700"}
                        borderWidth="1px"
                      >
                        {subtype.name}
                      </Button>
                      {!subtype.enabled && (
                        <Box
                          position="absolute"
                          top="-8px"
                          right="-8px"
                          bg="yellow.500"
                          color="black"
                          fontSize="xs"
                          fontWeight="bold"
                          px={1}
                          py={0.5}
                          borderRadius="full"
                          transform="rotate(15deg)"
                        >
                          {t("Soon")}
                        </Box>
                      )}
                    </Box>
                  ))}
                </SimpleGrid>
              </VStack>
            </Box>
            <Box flex="1">
              <Box bg="red.500" borderRadius="full" overflow="hidden" w="200" h="400" mx="auto">
                <Box as="img" 
                  src="/robotphone1.webp" 
                  alt="AI Phone Agent" 
                  w="full" 
                  h="full" 
                  objectFit="cover"
                />
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Container maxW="container.xl">
        <VStack spacing={20} align="stretch">
          <Box as="section" id="features" px={[6, 8, 10]} py={[8, 10, 12]} mt={20}>
            <Heading as="h2" size={headingSize} mb={[10, 14]} textAlign="center" color={textColor}>
              {t("Key Features")}
            </Heading>
            <Text fontSize="lg" mb={8} textAlign="center" color={textColor}>
              {t("Discover how our advanced AI technology can transform your customer service experience:")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={[6, 8, 10]}>
              {[
                { icon: FaRobot, title: t("AI-Powered Agents"), description: t("Utilize cutting-edge AI technology with finely tuned personalities for natural, efficient customer interactions.") },
                { icon: FaPhoneAlt, title: t("VoIP Integration"), description: t("Seamlessly integrate with your existing phone system for internal calls, transfers, and real-time information retrieval.") },
                { icon: FaChartLine, title: t("Advanced Analytics"), description: t("Gain valuable insights from call recordings, transcriptions, sentiment analysis, and comprehensive performance metrics.") },
                { icon: FaTools, title: t("Customizable Actions"), description: t("Access advanced tools like web search, database queries, and custom API integrations for tailored customer solutions.") },
                { icon: FaCalendarAlt, title: t("Scheduling Integration"), description: t("Seamlessly integrate with Google Calendar and other scheduling platforms for efficient appointment management.") },
                { icon: FaSearch, title: t("RAG Document Lookup"), description: t("Enhance agent knowledge with Retrieval-Augmented Generation for advanced document search and accurate information retrieval.") },
                { icon: FaLanguage, title: t("Multilingual Support"), description: t("Communicate with customers in multiple languages, breaking down language barriers and expanding your global reach.") },
                { icon: FaShieldAlt, title: t("Enhanced Security"), description: t("Implement robust security measures to protect sensitive customer information and maintain compliance with data protection regulations.") },
                { icon: FaClock, title: t("24/7 Availability"), description: t("Provide round-the-clock customer support without the need for human staff, ensuring consistent service quality at all times.") }
              ].map((feature, index) => (
                <FeatureCard
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  index={index}
                />
              ))}
            </SimpleGrid>
          </Box>

          <Box as="section" id="pricing" px={[6, 8, 10]} py={[8, 10, 12]} mt={20}>
            <Heading as="h2" size={headingSize} mb={[10, 14]} textAlign="center" color={textColor}>
              {t("Pricing")}
            </Heading>

            <Box overflowX="auto" mt={6}>
              <Table variant="simple" mb={8} size={useBreakpointValue({ base: "sm", md: "md" })}>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>{t("Beta Tester")}</Th>
                    <Th>{t("Additional")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{t("Price")}</Td>
                    <Td>{t("5$/month")}</Td>
                    <Td>-</Td>
                  </Tr>
                  <Tr>
                    <Td>{t("On call minutes")}</Td>
                    <Td>{t("60 minutes per month")}</Td>
                    <Td>0.05$/minute</Td>
                  </Tr>
                  <Tr>
                    <Td>{t("Spoken Characters")}</Td>
                    <Td>{t("100k per month")}</Td>
                    <Td>0.05$/1k characters</Td>
                  </Tr>
                  <Tr>
                    <Td>{t("Number of VoIP channels")}</Td>
                    <Td>2</Td>
                    <Td>-</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </VStack>
      </Container>

      <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
        <Container maxW="container.xl">
          <Flex direction={["column", "row"]} justify="space-between" align="center">
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
              <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
              <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
              <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
            </VStack>
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
              <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
            </VStack>
            <VStack align="flex-start">
              <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
              <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
            </VStack>
          </Flex>
          <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {"voxai"}. {t("All rights reserved.")}
          </Text>
        </Container>
      </Box>
      <Modal isOpen={isSignupModalOpen} onClose={() => setIsSignupModalOpen(false)}>
        <ModalOverlay />
        <ModalContent 
          bg="black" 
          color="white" 
          borderWidth="2px" 
          borderRadius="md"
          borderColor="gray.600"
        >
          <ModalHeader>{t("Request Early Access")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form 
              onSubmit={handleEarlyAccessSubmit} 
              style={{ 
                padding: '16px', 
                borderRadius: '8px', 
                background: 'black',
                border: '1px solid #4A5568'  // This is a dark gray color, you can adjust as needed
              }}
            >
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="firstName" color="gray.300">{t("First Name")}</FormLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder={t("Enter your first name")}
                    bg="black"
                    color="white"
                    _placeholder={{ color: "gray.500" }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="lastName" color="gray.300">{t("Last Name")}</FormLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder={t("Enter your last name")}
                    bg="black"
                    color="white"
                    _placeholder={{ color: "gray.500" }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="email" color="gray.300">{t("Email")}</FormLabel>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder={t("Enter your email")}
                    bg="black"
                    color="white"
                    _placeholder={{ color: "gray.500" }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="country" color="gray.300">{t("Country")}</FormLabel>
                  <Select
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    placeholder={t("Select your country")}
                    bg="black"
                    color="white"
                    _placeholder={{ color: "gray.500" }}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country}>{country}</option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
              <FormControl mt={4}>
                <Checkbox 
                  isChecked={tosAccepted}
                  onChange={(e) => setTosAccepted(e.target.checked)}
                >
                  {t("I accept the")} <Link color="blue.500" onClick={() => setShowTos(!showTos)}>{t("Terms of Service")}</Link>
                </Checkbox>
              </FormControl>
              <Collapse in={showTos} animateOpacity>
                <Box mt={4} p={4} bg="gray.700" borderRadius="md" maxHeight="200px" overflowY="auto">
                  <Text fontSize="sm">{t("tos")}</Text>
                </Box>
              </Collapse>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button 
              type="submit"
              width="full"
              variant="outline" 
              colorScheme="blue" 
              borderColor="blue.500" 
              color="white" 
              sx={animatedButtonStyles}
              _hover={{ '&::before': { opacity: 1 } }}
              onClick={handleEarlyAccessSubmit}
              isLoading={isSubmitting}
              loadingText={t("Submitting")}
              isDisabled={!tosAccepted}
            >
              {isSubmitting ? <Spinner size="sm" /> : <Text fontWeight="bold">{t("Request Early Access")}</Text>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LandingPage;
