import React from 'react';
import { Html } from '@react-email/html';
import { Button } from '@react-email/button';

const animatedButtonStyles = {
  background: 'black',
  color: 'white',
  padding: '12px 20px',
  border: '1px solid #3182CE', // This is the Chakra UI blue.500 color
  borderRadius: '4px',
  fontWeight: 'bold',
  textDecoration: 'none',
  display: 'inline-block',
  position: 'relative',
  overflow: 'hidden',
};

export const Email = ({ firstName, verificationUrl }) => (
  <Html>
    <body style={{ backgroundColor: 'black', color: 'white', fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <p>Hello {firstName},</p>
      <p>Thank you for requesting early access to VoxAI. Please click the following button to verify your email:</p>
      <Button href={verificationUrl} style={animatedButtonStyles}>
        Verify Email
      </Button>
      <p>If you didn't request this, please ignore this email.</p>
      <p>Best regards,<br />The VoxAI Team</p>
    </body>
  </Html>
);
