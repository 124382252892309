import React from 'react';
import { Box, Container, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';

const animatedButtonStyles = {
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    background: 'linear-gradient(45deg, #00BFFF, #1E90FF, #4169E1, #0000FF, #8A2BE2, #9932CC, #8B008B, #00BFFF)',
    backgroundSize: '400%',
    zIndex: -1,
    filter: 'blur(5px)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out, background-position 20s linear',
    borderRadius: 'inherit',
  },
  '&:hover::before': {
    opacity: 1,
    backgroundPosition: '100% 50%',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
    borderRadius: 'inherit',
    zIndex: -1,
  },
};

const VerificationSuccessPage = () => {
  const navigate = useNavigate();
  const bgColor = 'black';
  const textColor = 'gray.100';

  return (
    <Box bg={bgColor} minH="100vh" py={10} color={textColor}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="center">
          <Logo size="4xl" />
          <Heading as="h1" size="2xl" textAlign="center" color={textColor}>
            Email Verified Successfully!
          </Heading>
          <Text fontSize="xl" textAlign="center" color={textColor}>
            Thank you for signing up for early access to VoxAI. Your email has been verified, and you're now on the list for our beta release.
          </Text>
          <Text fontSize="lg" textAlign="center" color={textColor}>
            We'll contact you soon with more information about getting started with VoxAI.
          </Text>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={() => navigate('/')}
            variant="outline" 
            borderColor="blue.500" 
            color="white" 
            sx={animatedButtonStyles}
            _hover={{ '&::before': { opacity: 1 } }}
          >
            Return to Home
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};

export default VerificationSuccessPage;
