export const resources = {
  en: {
    translation: {
      // ... (keep existing translations)
      "VoxAI": "VoxAI",
      "call_to_action_title": "Ready-to-use AI phone agents.",
      "call_to_action_text": "Effortlessly streamline your operations, reduce costs, and enhance customer satisfaction with SIP based virtual agents that are prepared to handle calls instantly. Our intelligent call solution manages both inbound and outbound calls with speed, personalization, and 24/7 availability empowering your business to connect with customers like never before.",
      "AI-Powered Agents": "AI-Powered Agents",
      "Request Beta Access": "Request Beta Access",
      "Utilize cutting-edge AI technology with fined tunned personalities.": "Utilize cutting-edge AI technology with fined tunned personalities.",
      "VoIP Integration": "VoIP Integration",
      "Seamlessly integrate with your existing phone system for internal calls, transfers, and information retrieval.": "Seamlessly integrate with your existing phone system for internal calls, transfers, and information retrieval.",
      "Advanced Analytics": "Advanced Analytics",
      "Gain insights from call recordings, transcriptions, sentiment analysis, and performance metrics.": "Gain insights from call recordings, transcriptions, sentiment analysis, and performance metrics.",
      "Customizable Actions": "Customizable Actions",
      "Access advanced tools like web search, database queries, and custom API integrations.": "Access advanced tools like web search, database queries, and custom API integrations.",
      "Scheduling Integration": "Scheduling Integration",
      "Seamlessly integrate with Google Calendar for efficient scheduling actions.": "Seamlessly integrate with Google Calendar for efficient scheduling actions.",
      "RAG Document Lookup": "RAG Document Lookup",
      "Enhance agent knowledge with Retrieval-Augmented Generation for advanced document search and information retrieval.": "Enhance agent knowledge with Retrieval-Augmented Generation for advanced document search and information retrieval.",
      "Multilingual Support": "Multilingual Support",
      "Communicate with customers in multiple languages, breaking down language barriers.": "Communicate with customers in multiple languages, breaking down language barriers.",
      "Enhanced Security": "Enhanced Security",
      "Implement robust security measures to protect sensitive customer information and maintain compliance.": "Implement robust security measures to protect sensitive customer information and maintain compliance.",
      "24/7 Availability": "24/7 Availability",
      "Provide round-the-clock customer support without the need for human staff during off-hours.": "Provide round-the-clock customer support without the need for human staff during off-hours.",
      "Your Company Name": "Your Company Name",
      "All rights reserved.": "All rights reserved.",
      "I accept the": "I accept the",
      "Terms of Service": "Terms of Service",
      "Terms of Service not accepted": "Terms of Service not accepted",
      "Please accept the Terms of Service to continue.": "Please accept the Terms of Service to continue.",
      "tos": `Terms of Service (ToS) for VoxAI.
Last updated: 01-08-2024

Please read these Terms of Service ("Terms", "ToS") carefully before using our AI-enabled call services ("Service"). By using the Service, you ("User") agree to be bound by these Terms and accept full responsibility for all actions conducted through our platform, including but not limited to making AI-generated or assisted calls. If you do not agree to these Terms, you must not use the Service.

1. Acceptance of Responsibility
1.1. By using the Service, the User acknowledges and accepts full responsibility for all AI-generated or assisted communications made through the Service, including but not limited to calls, messages, or any other forms of communication.

1.2. The User agrees not to use the Service for any illegal, harmful, or malicious purposes and acknowledges that all actions carried out using the Service are their sole responsibility.

1.3. The User agrees to comply with all applicable local, state, national, and international laws and regulations governing the use of AI in telecommunications, including those related to privacy, data protection, consumer protection, and telecommunications laws.

2. Compliance with U.S. Law
2.1. The User must comply with U.S. federal laws, including the Telephone Consumer Protection Act (TCPA), Telemarketing Sales Rule (TSR), and any applicable regulations enforced by the Federal Communications Commission (FCC).

2.2. The User agrees to avoid making AI-generated or assisted calls to U.S. recipients who are on the National Do Not Call Registry, and the User shall be solely responsible for ensuring compliance with such laws.

2.3. The User agrees that any AI-generated or assisted robocalls or auto-dialed calls made without proper consent may be subject to fines and penalties under U.S. law. It is the User's responsibility to ensure that prior express written consent is obtained before engaging in telemarketing or automated calling practices.

3. Compliance with Canadian Law
3.1. The User must comply with Canada's Anti-Spam Legislation (CASL) and the Canadian Radio-television and Telecommunications Commission (CRTC) regulations governing telecommunications.

3.2. The User agrees to ensure that AI-generated or assisted calls made to Canadian residents comply with Canadian privacy laws, including the Personal Information Protection and Electronic Documents Act (PIPEDA).

3.3. The User acknowledges that unsolicited telemarketing calls to Canadian residents without explicit consent may result in penalties under Canadian law, and the User shall bear full responsibility for compliance.

4. Compliance with European Law
4.1. The User must comply with the General Data Protection Regulation (GDPR) and applicable national laws governing the use of AI and telecommunications within the European Economic Area (EEA).

4.2. The User agrees to obtain explicit consent from individuals before initiating AI-generated or assisted calls in the EEA, and to respect the right to be forgotten, as well as other data subject rights as defined by GDPR.

4.3. The User acknowledges that breaches of GDPR, such as failing to obtain appropriate consent for marketing calls, may result in significant fines, and the User shall be solely liable for such breaches.

5. Data and Privacy
5.1. The User agrees that any data collected, processed, or transmitted through the Service shall be handled in compliance with the privacy laws of the relevant jurisdictions, including but not limited to the California Consumer Privacy Act (CCPA) in the U.S., PIPEDA in Canada, and GDPR in the EEA.

5.2. The User is responsible for ensuring that all personal data processed via the Service, including audio recordings or transcripts, is obtained lawfully, and that individuals' privacy rights are respected.

6. Indemnification
6.1. The User agrees to indemnify and hold harmless the Service provider from and against any claims, damages, liabilities, costs, or expenses (including legal fees) arising from the User's use of the Service in violation of any applicable law or regulation, including, but not limited to, any unauthorized or illegal use of AI in telecommunications.

7. Limitation of Liability
7.1. The Service provider shall not be held liable for any damages arising from the User's misuse or non-compliance with applicable laws or these Terms in relation to AI-generated or assisted communications.

7.2. In no event shall the Service provider be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the User's use of the Service, even if advised of the possibility of such damages.

8. Termination
8.1. The Service provider reserves the right to suspend or terminate access to the Service at any time, without prior notice, for any breach of these Terms or any applicable laws or regulations.

9. Governing Law
9.1. These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law principles.

9.2. Any disputes arising from or relating to these Terms shall be resolved exclusively in the courts of [Insert Jurisdiction].

10. Amendments
10.1. The Service provider reserves the right to amend these Terms at any time. Users will be notified of any material changes and continued use of the Service following such notice will constitute acceptance of the updated Terms.

By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.`
    }
  },
  fr: {
    translation: {
      // ... (keep existing translations)
      "call_to_action_title": "Agents téléphoniques IA prêts à l'emploi.",
      "call_to_action_text": "Rationalisez vos opérations sans effort, réduisez les coûts et améliorez la satisfaction client grâce à des agents virtuels SIP prêts à gérer les appels instantanément. Notre solution d'appels intelligente gère les appels entrants et sortants avec rapidité, personnalisation et disponibilité 24h/24 et 7j/7, permettant à votre entreprise de se connecter avec les clients comme jamais auparavant.",
      "Request Beta Access": "Demander l'accès Beta",
      "AI-Powered Agents": "Agents alimentés par l'IA",
      "Utilize cutting-edge AI technology with fined tunned personalities.": "Utilisez une technologie d'IA de pointe avec des personnalités finement ajustées.",
      "VoIP Integration": "Intégration VoIP",
      "Seamlessly integrate with your existing phone system for internal calls, transfers, and information retrieval.": "Intégrez de manière transparente votre système téléphonique existant pour les appels internes, les transferts et la récupération d'informations.",
      "Advanced Analytics": "Analyses avancées",
      "Gain insights from call recordings, transcriptions, sentiment analysis, and performance metrics.": "Obtenez des informations à partir des enregistrements d'appels, des transcriptions, de l'analyse des sentiments et des mesures de performance.",
      "Customizable Actions": "Actions personnalisables",
      "Access advanced tools like web search, database queries, and custom API integrations.": "Accédez à des outils avancés tels que la recherche web, les requêtes de base de données et les intégrations API personnalisées.",
      "Scheduling Integration": "Intégration de planification",
      "Seamlessly integrate with Google Calendar for efficient scheduling actions.": "Intégrez de manière transparente Google Calendar pour des actions de planification efficaces.",
      "RAG Document Lookup": "Recherche de documents RAG",
      "Enhance agent knowledge with Retrieval-Augmented Generation for advanced document search and information retrieval.": "Améliorez les connaissances des agents grâce à la génération augmentée par récupération pour une recherche avancée de documents et une récupération d'informations.",
      "Multilingual Support": "Support multilingue",
      "Communicate with customers in multiple languages, breaking down language barriers.": "Communiquez avec les clients dans plusieurs langues, en brisant les barrières linguistiques.",
      "Enhanced Security": "Sécurité renforcée",
      "Implement robust security measures to protect sensitive customer information and maintain compliance.": "Mettez en place des mesures de sécurité robustes pour protéger les informations sensibles des clients et maintenir la conformité.",
      "24/7 Availability": "Disponibilité 24/7",
      "Provide round-the-clock customer support without the need for human staff during off-hours.": "Fournissez un support client 24h/24 et 7j/7 sans avoir besoin de personnel humain pendant les heures creuses.",
      "Your Company Name": "Nom de votre entreprise",
      "All rights reserved.": "Tous droits réservés.",
      "Hire Agents Now": "Engagez des agents maintenant",
      "Experience the future of customer service with VoxAI's AI-powered call solution. Streamline your operations, reduce costs, and enhance customer satisfaction with intelligent virtual agents that make and receive phone calls.": "Découvrez l'avenir du service client avec la solution d'appel alimentée par l'IA de VoxAI. Optimisez vos opérations, réduisez vos coûts et améliorez la satisfaction client grâce à des agents virtuels intelligents qui passent et reçoivent des appels téléphoniques.",
      "receive": "reçoivent",
      "phone calls": "appels téléphoniques",
      "Receptionist": "Réceptionniste",
      "Custom": "Personnalisé",
      "Technical Support": "Support technique",
      "Information Line": "Ligne d'information",
      "Restaurant Order": "Commande de restaurant",
      "Taxi dispatcher": "Répartiteur de taxi",
      "Cold Caller": "Démarcheur téléphonique",
      "Appointment Confirmation": "Confirmation de rendez-vous",
      "Soon": "Bientôt",
      "AI Agents for Enhanced Customer Service": "Agents IA pour un service client amélioré",
      "I accept the": "J'accepte les",
      "Terms of Service": "Conditions d'Utilisation",
      "Terms of Service not accepted": "Conditions d'Utilisation non acceptées",
      "Please accept the Terms of Service to continue.": "Veuillez accepter les Conditions d'Utilisation pour continuer.",
      "tos": `Conditions d'Utilisation (CdU) de VoxAI.
Dernière mise à jour : 01-08-2024

Veuillez lire attentivement ces Conditions d'Utilisation ("Conditions", "CdU") avant d'utiliser nos services d'appel activés par IA ("Service"). En utilisant le Service, vous ("Utilisateur") acceptez d'être lié par ces Conditions et assumez l'entière responsabilité de toutes les actions effectuées via notre plateforme, y compris, sans s'y limiter, les appels générés ou assistés par IA. Si vous n'acceptez pas ces Conditions, vous ne devez pas utiliser le Service.

Acceptation de la Responsabilité
1.1. En utilisant le Service, l'Utilisateur reconnaît et accepte l'entière responsabilité de toutes les communications générées ou assistées par IA effectuées via le Service, y compris, sans s'y limiter, les appels, messages ou toute autre forme de communication.
1.2. L'Utilisateur s'engage à ne pas utiliser le Service à des fins illégales, nuisibles ou malveillantes et reconnaît que toutes les actions effectuées en utilisant le Service relèvent de sa seule responsabilité.

1.3. L'Utilisateur s'engage à se conformer à toutes les lois et réglementations locales, nationales, internationales et supranationales applicables régissant l'utilisation de l'IA dans les télécommunications, y compris celles liées à la confidentialité, la protection des données, la protection des consommateurs et les lois sur les télécommunications.

Conformité avec la législation américaine
2.1. L'Utilisateur doit se conformer aux lois fédérales américaines, y compris la Loi sur la Protection des Consommateurs en matière de Télécommunications (TCPA), la Règle sur les Ventes par Téléphone (TSR), et toutes les réglementations applicables mises en œuvre par la Commission Fédérale des Communications (FCC).
2.2. L'Utilisateur s'engage à éviter de passer des appels générés ou assistés par IA à des destinataires américains inscrits au Registre National de Ne Pas Appeler, et l'Utilisateur sera seul responsable de la conformité avec ces lois.

2.3. L'Utilisateur reconnaît que tout appel robotisé ou automatisé généré ou assisté par IA, effectué sans consentement préalable adéquat, peut entraîner des amendes et des pénalités en vertu de la législation américaine. Il est de la responsabilité de l'Utilisateur de s'assurer que le consentement exprès préalable est obtenu avant de procéder à des pratiques de télémarketing ou d'appels automatisés.

Conformité avec la législation canadienne
3.1. L'Utilisateur doit se conformer à la Loi canadienne anti-pourriel (LCAP) et aux règlements de la Commission Canadienne de la Radio-télévision et des Télécommunications (CRTC) régissant les télécommunications.
3.2. L'Utilisateur s'engage à s'assurer que les appels générés ou assistés par IA à des résidents canadiens respectent les lois canadiennes sur la confidentialité, y compris la Loi sur la Protection des Renseignements Personnels et les Documents Électroniques (LPRPDE).

3.3. L'Utilisateur reconnaît que des appels de télémarketing non sollicités à des résidents canadiens sans consentement explicite peuvent entraîner des sanctions en vertu de la législation canadienne, et l'Utilisateur assumera l'entière responsabilité de la conformité.

Conformité avec la législation européenne
4.1. L'Utilisateur doit se conformer au Règlement Général sur la Protection des Données (RGPD) et aux lois nationales applicables régissant l'utilisation de l'IA et des télécommunications dans l'Espace Économique Européen (EEE).
4.2. L'Utilisateur s'engage à obtenir le consentement explicite des individus avant de passer des appels générés ou assistés par IA dans l'EEE, et à respecter le droit à l'oubli, ainsi que les autres droits des personnes concernées tels que définis par le RGPD.

4.3. L'Utilisateur reconnaît que les violations du RGPD, telles que le fait de ne pas obtenir le consentement adéquat pour des appels marketing, peuvent entraîner des amendes importantes, et l'Utilisateur sera seul responsable de telles violations.

Données et Confidentialité
5.1. L'Utilisateur s'engage à ce que toutes les données collectées, traitées ou transmises via le Service soient traitées en conformité avec les lois de confidentialité des juridictions pertinentes, y compris, sans s'y limiter, la California Consumer Privacy Act (CCPA) aux États-Unis, la LPRPDE au Canada et le RGPD dans l'EEE.
5.2. L'Utilisateur est responsable de s'assurer que toutes les données personnelles traitées via le Service, y compris les enregistrements audio ou les transcriptions, sont obtenues de manière légale, et que les droits à la vie privée des individus sont respectés.

Indemnisation
6.1. L'Utilisateur s'engage à indemniser et à dégager de toute responsabilité le prestataire de Service contre toute réclamation, dommage, responsabilité, coût ou dépense (y compris les frais juridiques) découlant de l'utilisation du Service par l'Utilisateur en violation de toute loi ou réglementation applicable, y compris, mais sans s'y limiter, toute utilisation non autorisée ou illégale de l'IA dans les télécommunications.

Limitation de Responsabilité
7.1. Le prestataire de Service ne pourra être tenu responsable des dommages résultant de l'utilisation inappropriée ou de la non-conformité de l'Utilisateur avec les lois applicables ou avec ces Conditions en ce qui concerne les communications générées ou assistées par IA.

7.2. En aucun cas, le prestataire de Service ne pourra être tenu responsable de tout dommage indirect, accessoire, spécial ou consécutif découlant de ou en rapport avec l'utilisation du Service par l'Utilisateur, même si le prestataire a été informé de la possibilité de tels dommages.

Résiliation
8.1. Le prestataire de Service se réserve le droit de suspendre ou de résilier l'accès au Service à tout moment, sans préavis, en cas de violation de ces Conditions ou de toute loi ou réglementation applicable.

Droit Applicable
9.1. Ces Conditions seront régies et interprétées conformément aux lois de [Insérer la Juridiction], sans tenir compte des principes de conflit de lois.

9.2. Tout litige découlant de ou lié à ces Conditions sera résolu exclusivement devant les tribunaux de [Insérer la Juridiction].

Amendements
10.1. Le prestataire de Service se réserve le droit de modifier ces Conditions à tout moment. Les Utilisateurs seront informés de tout changement important, et la poursuite de l'utilisation du Service après cette notification constituera une acceptation des Conditions mises à jour.
En utilisant le Service, vous reconnaissez avoir lu, compris et accepté d'être lié par ces Conditions.`,
      "VoxAI": "VoxAI",
      "Pricing": "Tarification",
      "Beta Tester": "Testeur Beta",
      "Additional": "Supplémentaire",
      "Price": "Prix",
      "5$/month": "5$/mois",
      "On call minutes": "Minutes d'appel",
      "Spoken Characters": "Caractères parlés",
      "100k per month": "100k par mois",
      "60 minutes per month": "60 minutes par mois",
      "Number of VoIP channels": "Nombre de canaux VoIP",
      "Key Features": "Fonctionnalités Clés",
      "Discover how our advanced AI technology can transform your customer service experience:": "Découvrez comment notre technologie d'IA avancée peut transformer votre expérience de service client :",
      "Utilize cutting-edge AI technology with finely tuned personalities for natural, efficient customer interactions.": "Utilisez une technologie d'IA de pointe avec des personnalités finement ajustées pour des interactions client naturelles et efficaces.",
      "Seamlessly integrate with your existing phone system for internal calls, transfers, and real-time information retrieval.": "Intégrez de manière transparente votre système téléphonique existant pour les appels internes, les transferts et la récupération d'informations en temps réel.",
      "Gain valuable insights from call recordings, transcriptions, sentiment analysis, and comprehensive performance metrics.": "Obtenez des informations précieuses à partir des enregistrements d'appels, des transcriptions, de l'analyse des sentiments et des mesures de performance complètes.",
      "Access advanced tools like web search, database queries, and custom API integrations for tailored customer solutions.": "Accédez à des outils avancés tels que la recherche web, les requêtes de base de données et les intégrations API personnalisées pour des solutions client sur mesure.",
      "Seamlessly integrate with Google Calendar and other scheduling platforms for efficient appointment management.": "Intégrez de manière transparente Google Calendar et d'autres plateformes de planification pour une gestion efficace des rendez-vous.",
      "Enhance agent knowledge with Retrieval-Augmented Generation for advanced document search and accurate information retrieval.": "Améliorez les connaissances des agents grâce à la génération augmentée par récupération pour une recherche avancée de documents et une récupération précise d'informations.",
      "Communicate with customers in multiple languages, breaking down language barriers and expanding your global reach.": "Communiquez avec les clients dans plusieurs langues, en brisant les barrières linguistiques et en élargissant votre portée mondiale.",
      "Implement robust security measures to protect sensitive customer information and maintain compliance with data protection regulations.": "Mettez en place des mesures de sécurité robustes pour protéger les informations sensibles des clients et maintenir la conformité avec les réglementations sur la protection des données.",
      "Provide round-the-clock customer support without the need for human staff, ensuring consistent service quality at all times.": "Fournissez un support client 24h/24 et 7j/7 sans avoir besoin de personnel humain, assurant une qualité de service constante à tout moment.",
      "Company": "Entreprise",
      "About Us": "À propos de nous",
      "Contact": "Contact",
      "Privacy Policy": "Politique de confidentialité",
      "Resources": "Ressources",
      "Documentation": "Documentation",
      "Blog": "Blog",
      "Connect": "Connexion",
      "About VoxAI": "À propos de VoxAI",
      "Revolutionizing customer interactions with AI-powered phone agents": "Révolutionner les interactions avec les clients grâce aux agents téléphoniques alimentés par l'IA",
      "Our Mission": "Notre Mission",
      "To empower businesses with cutting-edge AI technology, transforming how they communicate with customers and streamline operations.": "Doter les entreprises d'une technologie d'IA de pointe, transformant leur façon de communiquer avec les clients et de rationaliser leurs opérations.",
      "Our Team": "Notre Équipe",
      "A diverse group of AI experts, developers, and customer experience specialists dedicated to pushing the boundaries of what's possible in AI-human interaction.": "Un groupe diversifié d'experts en IA, de développeurs et de spécialistes de l'expérience client dédiés à repousser les limites du possible dans l'interaction IA-humain.",
      "Innovation": "Innovation",
      "Constantly evolving our AI models and integrations to provide the most advanced, natural, and efficient phone agent experience in the market.": "Faire évoluer constamment nos modèles d'IA et nos intégrations pour offrir l'expérience d'agent téléphonique la plus avancée, naturelle et efficace du marché.",
      "Global Impact": "Impact Global",
      "Helping businesses around the world improve customer satisfaction, reduce costs, and scale their operations with our AI-powered solutions.": "Aider les entreprises du monde entier à améliorer la satisfaction client, réduire les coûts et développer leurs opérations grâce à nos solutions alimentées par l'IA.",
      "Our Story": "Notre Histoire",
      "Founded in 2023, VoxAI emerged from a vision to revolutionize customer service using advanced AI technology. Our journey began with a simple idea: what if AI could handle phone calls as naturally and effectively as humans? Today, we're at the forefront of AI-powered communication, constantly innovating to create seamless, intelligent interactions between businesses and their customers.": "Fondée en 2023, VoxAI est née d'une vision visant à révolutionner le service client en utilisant une technologie d'IA avancée. Notre voyage a commencé par une idée simple : et si l'IA pouvait gérer les appels téléphoniques aussi naturellement et efficacement que les humains ? Aujourd'hui, nous sommes à l'avant-garde de la communication alimentée par l'IA, innovant constamment pour créer des interactions intelligentes et fluides entre les entreprises et leurs clients.",
      "Learn More": "En Savoir Plus",
      "Contact Us": "Contactez-nous",
      "We're here to help and answer any question you might have": "Nous sommes là pour vous aider et répondre à toutes vos questions",
      "Email": "E-mail",
      "Phone": "Téléphone",
      "Your Name": "Votre nom",
      "Your Email": "Votre e-mail",
      "Subject": "Sujet",
      "Your Message": "Votre message",
      "Send Message": "Envoyer le message",
      "Message Sent": "Message envoyé",
      "We've received your message and will get back to you soon.": "Nous avons reçu votre message et nous vous répondrons bientôt.",
      // Privacy Policy translations
      "Last updated: 01-08-2024": "Dernière mise à jour : 01-08-2024",
      "At VoxAI, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI-enabled call services ('Service'). By using our Service, you agree to the collection and use of information in accordance with this policy.": "Chez VoxAI, nous nous engageons à protéger votre vie privée et à assurer la sécurité de vos informations personnelles. Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez nos services d'appel activés par l'IA ('Service'). En utilisant notre Service, vous acceptez la collecte et l'utilisation d'informations conformément à cette politique.",
      "1. Information We Collect": "1. Informations que nous collectons",
      "We collect the following types of information:": "Nous collectons les types d'informations suivants :",
      "Personal information (e.g., name, email address, phone number)": "Informations personnelles (par exemple, nom, adresse e-mail, numéro de téléphone)",
      "Account information": "Informations de compte",
      "Payment information": "Informations de paiement",
      "Call recordings and transcripts": "Enregistrements d'appels et transcriptions",
      "Usage data and analytics": "Données d'utilisation et analyses",
      "2. How We Use Your Information": "2. Comment nous utilisons vos informations",
      "We use the collected information for various purposes, including:": "Nous utilisons les informations collectées à diverses fins, notamment :",
      "Providing and maintaining our Service": "Fournir et maintenir notre Service",
      "Improving and personalizing user experience": "Améliorer et personnaliser l'expérience utilisateur",
      "Processing transactions and sending related information": "Traiter les transactions et envoyer des informations connexes",
      "Sending technical notices, updates, and support messages": "Envoyer des avis techniques, des mises à jour et des messages de support",
      "Responding to your comments and questions": "Répondre à vos commentaires et questions",
      "Complying with legal obligations": "Se conformer aux obligations légales",
      "3. Data Security": "3. Sécurité des données",
      "We implement appropriate technical and organizational measures to protect the security of your personal information. This includes encryption of sensitive data, regular security audits, and strict access controls. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.": "Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger la sécurité de vos informations personnelles. Cela inclut le chiffrement des données sensibles, des audits de sécurité réguliers et des contrôles d'accès stricts. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est 100% sécurisée.",
      "4. Compliance with Laws": "4. Conformité aux lois",
      "We comply with applicable data protection laws, including:": "Nous nous conformons aux lois applicables sur la protection des données, notamment :",
      "General Data Protection Regulation (GDPR) for users in the European Economic Area (EEA)": "Règlement général sur la protection des données (RGPD) pour les utilisateurs de l'Espace économique européen (EEE)",
      "California Consumer Privacy Act (CCPA) for California residents": "California Consumer Privacy Act (CCPA) pour les résidents de Californie",
      "Personal Information Protection and Electronic Documents Act (PIPEDA) for Canadian users": "Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE) pour les utilisateurs canadiens",
      "5. Your Rights": "5. Vos droits",
      "Depending on your location, you may have certain rights regarding your personal data, including:": "Selon votre localisation, vous pouvez avoir certains droits concernant vos données personnelles, notamment :",
      "The right to access your personal data": "Le droit d'accéder à vos données personnelles",
      "The right to rectify inaccurate personal data": "Le droit de rectifier les données personnelles inexactes",
      "The right to erasure ('right to be forgotten')": "Le droit à l'effacement ('droit à l'oubli')",
      "The right to restrict processing": "Le droit de limiter le traitement",
      "The right to data portability": "Le droit à la portabilité des données",
      "The right to object to processing": "Le droit de s'opposer au traitement",
      "6. Changes to This Privacy Policy": "6. Modifications de cette politique de confidentialité",
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last updated' date. You are advised to review this Privacy Policy periodically for any changes.": "Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page et en mettant à jour la date de 'Dernière mise à jour'. Nous vous conseillons de consulter régulièrement cette Politique de Confidentialité pour prendre connaissance des éventuelles modifications.",
      "7. Contact Us": "7. Contactez-nous",
      "If you have any questions about this Privacy Policy or our data practices, please contact us at:": "Si vous avez des questions concernant cette Politique de Confidentialité ou nos pratiques en matière de données, veuillez nous contacter à :",
      "Email: privacy@voxai.app": "E-mail : privacy@voxai.app",
      "By using our Service, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.": "En utilisant notre Service, vous reconnaissez avoir lu et compris cette Politique de Confidentialité et acceptez ses conditions.",
      
      // Documentation translations
      "VoxAI Documentation": "Documentation VoxAI",
      "Welcome to the VoxAI documentation. Here you'll find everything you need to know about setting up and using our AI-powered phone agents.": "Bienvenue dans la documentation VoxAI. Vous trouverez ici tout ce que vous devez savoir sur la configuration et l'utilisation de nos agents téléphoniques alimentés par l'IA.",
      "Getting Started": "Commencer",
      "To get started with VoxAI, follow these steps:": "Pour commencer avec VoxAI, suivez ces étapes :",
      "Sign up for an account": "Créez un compte",
      "Set up your first AI agent": "Configurez votre premier agent IA",
      "Configure your phone system integration": "Configurez l'intégration de votre système téléphonique",
      "Test your AI agent": "Testez votre agent IA",
      "Read full guide": "Lire le guide complet",
      "AI Agent Configuration": "Configuration de l'Agent IA",
      "Learn how to configure your AI agents for optimal performance:": "Apprenez à configurer vos agents IA pour des performances optimales :",
      "Personality settings": "Paramètres de personnalité",
      "Language models": "Modèles de langage",
      "Custom knowledge bases": "Bases de connaissances personnalisées",
      "Response templates": "Modèles de réponse",
      "Integration Guide": "Guide d'Intégration",
      "Integrate VoxAI with your existing systems:": "Intégrez VoxAI à vos systèmes existants :",
      "VoIP systems": "Systèmes VoIP",
      "CRM platforms": "Plateformes CRM",
      "Scheduling software": "Logiciels de planification",
      "Custom APIs": "APIs personnalisées",
      "Analytics and Reporting": "Analyses et Rapports",
      "Understand your AI agents' performance:": "Comprenez les performances de vos agents IA :",
      "Call statistics": "Statistiques d'appels",
      "Sentiment analysis": "Analyse des sentiments",
      "Conversion rates": "Taux de conversion",
      "Custom reports": "Rapports personnalisés",
      "Troubleshooting": "Dépannage",
      "Common issues and their solutions:": "Problèmes courants et leurs solutions :",
      "Connection problems": "Problèmes de connexion",
      "Audio quality issues": "Problèmes de qualité audio",
      "AI response accuracy": "Précision des réponses de l'IA",
      "Integration errors": "Erreurs d'intégration",
      "Need more help?": "Besoin d'aide supplémentaire ?",
      "Contact our support team at": "Contactez notre équipe de support à",
      "🚧 Under Construction 🚧": "🚧 En Construction 🚧",
      "We're working hard to bring you comprehensive documentation. Check back soon!": "Nous travaillons dur pour vous apporter une documentation complète. Revenez bientôt !",

      // Blog translations
      "VoxAI Blog": "Blog VoxAI",
      "Insights, updates, and stories about AI-powered customer service": "Perspectives, mises à jour et histoires sur le service client alimenté par l'IA",
      "The Future of Customer Service: AI-Powered Phone Agents": "L'avenir du service client : les agents téléphoniques alimentés par l'IA",
      "Explore how AI is revolutionizing the customer service industry, providing 24/7 support and personalized experiences.": "Découvrez comment l'IA révolutionne l'industrie du service client, offrant un support 24/7 et des expériences personnalisées.",
      "Read More": "Lire la suite",
      "Load More Posts": "Charger plus d'articles"
    }
  }
};
