import React from 'react';
import { Box, Heading, Text, Container, SimpleGrid, Icon, VStack, useColorModeValue, Button, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRocket, FaUsers, FaLightbulb, FaGlobe } from 'react-icons/fa';
import Logo from '../components/Logo';
import { Helmet } from 'react-helmet';

const FeatureCard = ({ icon, title, description }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box 
      bg={cardBg} 
      p={6} 
      borderRadius="lg" 
      boxShadow="md" 
      border="1px"
      borderColor={borderColor}
    >
      <Icon as={icon} boxSize={12} color="blue.500" mb={4} />
      <Heading as="h3" size="md" mb={2}>
        {title}
      </Heading>
      <Text>{description}</Text>
    </Box>
  );
};

const AboutUs = () => {
  const { t } = useTranslation();
  const bgColor = 'black';
  const textColor = 'gray.100';

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Helmet>
        <title>About VoxAI - Pioneering AI-Powered Customer Service Solutions</title>
        <meta name="description" content="Discover VoxAI's journey in revolutionizing customer service with AI-powered phone agents. Learn about our mission, team, and innovative approach to transforming business communication." />
        <link rel="canonical" href="https://voxai.app/about" />
        <meta property="og:title" content="About VoxAI - Pioneering AI-Powered Customer Service Solutions" />
        <meta property="og:description" content="Discover VoxAI's journey in revolutionizing customer service with AI-powered phone agents. Learn about our mission, team, and innovative approach to transforming business communication." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://voxai.app/about" />
        <meta property="og:image" content="https://voxai.app/about-og-image.jpg" />
        <meta property="og:site_name" content="VoxAI" />
      </Helmet>
      <Container maxW="container.xl" py={20}>
        <VStack spacing={10} align="stretch">
          <Flex justifyContent="center" mb={6}>
            <RouterLink to="/">
              <Logo size="4xl" text="VoxAI" />
            </RouterLink>
          </Flex>
          <Box textAlign="center" mb={10}>
            <Heading as="h1" size="2xl" mb={4}>
              {t("About VoxAI")}
            </Heading>
            <Text fontSize="xl">
              {t("Revolutionizing customer interactions with AI-powered phone agents")}
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <FeatureCard
              icon={FaRocket}
              title={t("Our Mission")}
              description={t("To empower businesses with cutting-edge AI technology, transforming how they communicate with customers and streamline operations.")}
            />
            <FeatureCard
              icon={FaUsers}
              title={t("Our Team")}
              description={t("A diverse group of AI experts, developers, and customer experience specialists dedicated to pushing the boundaries of what's possible in AI-human interaction.")}
            />
            <FeatureCard
              icon={FaLightbulb}
              title={t("Innovation")}
              description={t("Constantly evolving our AI models and integrations to provide the most advanced, natural, and efficient phone agent experience in the market.")}
            />
            <FeatureCard
              icon={FaGlobe}
              title={t("Global Impact")}
              description={t("Helping businesses around the world improve customer satisfaction, reduce costs, and scale their operations with our AI-powered solutions.")}
            />
          </SimpleGrid>

          <Box mt={10}>
            <Heading as="h2" size="xl" mb={4}>
              {t("Our Story")}
            </Heading>
            <Text fontSize="lg">
              {t("Founded in 2023, VoxAI emerged from a vision to revolutionize customer service using advanced AI technology. Our journey began with a simple idea: what if AI could handle phone calls as naturally and effectively as humans? Today, we're at the forefront of AI-powered communication, constantly innovating to create seamless, intelligent interactions between businesses and their customers.")}
            </Text>
          </Box>
          <Box textAlign="center" mt={10}>
            <Button
              colorScheme="purple"
              size="lg"
              fontWeight="bold"
              px={8}
              borderRadius="full"
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              {t("Learn More")}
            </Button>
          </Box>
        </VStack>
      </Container>

      <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
        <Container maxW="container.xl">
          <Flex direction={["column", "row"]} justify="space-between" align="center">
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
              <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
              <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
              <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
            </VStack>
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
              <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
            </VStack>
            <VStack align="flex-start">
              <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
              <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
            </VStack>
          </Flex>
          <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {"voxai"}. {t("All rights reserved.")}
          </Text>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
